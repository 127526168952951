import Axios from 'axios'

console.log(process.env.VUE_APP_BASE_URI)

export default Axios.create({
    baseURL: process.env.VUE_APP_BASE_URI
        // headers: {
        //   'Content-Type': 'application/vnd.api+json',
        //   Authorization: 'Bearer {token}'
        // }
})