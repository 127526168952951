<template>
  <div class="hero-head">
    <nav class="navbar grey"
         id="nav">
      <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item"
             href="../">
            <img src="../assets/images/logo.png"
                 alt="Logo">
          </a>
          <span class="navbar-burger burger"
                data-target="navbarMenu">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarMenu"
             class="navbar-menu">
          <div class="navbar-end">
            <div class="tabs is-right">
              <ul>
                <li>
                  <router-link to="/scan">Scan</router-link>
                </li>
                <li>
                  <router-link to="/kiosk">Kiosk</router-link>
                </li>
                <li>
                  <router-link to="/settings">Settings</router-link>
                </li>
                <li>
                  <router-link to="/help">Help</router-link>
                </li>              
                <li>
                  <a @click='logoutClick'>Logout</a>
                </li>
              </ul>
            </div>
            <!-- <transition name="slideup">
              <div class="offline-indicator" v-if="!isOnline">{{ statusMessage }}</div>
            </transition> -->
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { EventBus } from '../event-bus.js'
import { mapActions } from 'vuex'

export default {
  name: "Navigation",
  props: {
    msg: String,
    message: String
  },
  computed: {

    statusMessage () {
      if (typeof this.message !== 'undefined') {
        return this.message
      }
      return 'You are offline.'
    }

  },
  mounted: function () {
    EventBus.$on('network.changed', data => {
      //console.log(`network is ${data ? 'online' : 'offline'}`)
      this.isOnline = data
    })


    // Get all "navbar-burger" elements
    var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(function ($el) {
        $el.addEventListener('click', function () {

          // Get the target from the "data-target" attribute
          var target = $el.dataset.target;
          var $target = document.getElementById(target);

          // Toggle the class on both the "navbar-burger" and the "navbar-menu"
          $el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }

  },
  data () {
    return {
      isOnline: true
    }
  },
  methods: {
    ...mapActions(['logout']),
    logoutClick () {
      this.logout({ router: this.$router })
    }
  }


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
