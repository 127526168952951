<template>
  <div class="about">
    <h1>App Settings</h1>
    <section>
      <div class="field">
        <b-switch v-model="turnOffIdleCamera"
                  @input="handleToggle"
                  size="is-medium">
          Disable Camera After 60secs Inactivity
        </b-switch>
      </div>
      <div class="field">
        <b-switch v-model="localLaserScanEnabled"
                  @input="handleToggleLaserScan"
                  size="is-medium">
          Enable QR Code Scanner
        </b-switch>
      </div>

      <div class="field">
        <a @click="$router.go(-1)">Back</a>
      </div>

    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Settings",
  mounted () {
    this.localLaserScanEnabled = this.laserScanEnabled;
  },
  computed: {
    ...mapGetters(['cameraIdleTimeout', 'laserScanEnabled']),
  },
  methods: {
    ...mapActions(['toggleIdleTimeout', 'toggleLaserScanOption']),
    handleToggle () {
      this.toggleIdleTimeout({ enabled: this.turnOffIdleCamera })
    },
    handleToggleLaserScan() {
      this.toggleLaserScanOption({ enabled: this.localLaserScanEnabled })
    }
  },
  data () {
    return {
      turnOffIdleCamera: true,
      localLaserScanEnabled: true,
    }
  }

};
</script>