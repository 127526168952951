<template>
  <section class="hero is-success is-fullheight">

    <div class="hero-body login-page">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <div class="box">
            <figure class="avatar">
              <img src="../assets/images/Icon-128.png">
            </figure>
            <form @submit.prevent="checkCreds">
              <div class="field">
                <div class="control">
                  <input class="input is-large" type="email" placeholder="Your Email" autofocus="" v-model="username">
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input class="input is-large" type="password" placeholder="Your Password" v-model="password">
                </div>
              </div>
              <button type="submit" class="button is-block is-info is-large is-fullwidth">Login</button>
            </form>
          </div>
          <div v-if="loggedIn"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import api from '@/api/api'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    loggedIn () {
      const result = this.$store.getters.getToken != ''
      if (result) {
        // eslint-disable-next-line
        this.$router.push('scan')
      }
      return result
    }
  },
  methods: {
    ...mapActions(['login']),
    checkCreds () {
      const { username, password } = this

      this.$store.dispatch('login', { username: this.username, password: this.password, router: this.$router })
    },

  },

}

</script>

<style>

</style>