import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
//import './registerServiceWorker'
import '@/assets/main.scss'

import { EventBus } from './event-bus.js'

Vue.use(Buefy)

require('./assets/main.scss')

Vue.config.productionTip = false

var version = '[AIV]{version} | {date}[/AIV]'

new Vue({
    data() {
        return {
            version: version
        }
    },
    methods: {
        updateStatus: function() {
            var isOnline = false
            if (typeof window.navigator.onLine === 'undefined') {
                // If the browser doesn't support connection status reports
                // assume that we are online because most apps' only react
                // when they now that the connection has been interrupted
                isOnline = true
            } else {
                isOnline = window.navigator.onLine
            }

            EventBus.$emit('network.changed', isOnline)
        }
    },

    created() {
        //load saved token from dexie
        this.$store.dispatch('loadSavedToken')
    },

    mounted() {
        const vm = this
            // Setup the listeners
        window.addEventListener('online', this.updateStatus)
        window.addEventListener('offline', this.updateStatus)

        EventBus.$on('warning', data => {
            this.$snackbar.open({
                message: `${data}`,
                type: 'is-warning',
                position: 'is-top',
                actionText: 'OK',
                duration: 5000,
                queue: false,
                onAction: () => {}
            })
        })

        EventBus.$on('error', data => {
            this.$snackbar.open({
                message: `${data}`,
                type: 'is-danger custom-class',
                position: 'is-top',
                queue: false,
                actionText: 'OK',
                duration: 5000,
                onAction: () => {}
            })
        })

        EventBus.$on('success', data => {
            if (data) {
                this.$toast.open({
                    message: `${data}`,
                    type: 'is-success',
                    position: 'is-top',
                    queue: false
                })
            }
        })

        //setup service worker controller
        if (navigator.serviceWorker) {
            if (window.BroadcastChannel) {
                const stripString = (input, strip) => {
                    if (input.endsWith(strip)) {
                        return input.substring(0, input.length - strip.length)
                    }

                    return input
                }

                const noAction = url => url
                const stripHtml = url => stripString(url, '.html')
                const stripIndex = url => stripString(url, 'index')

                const mutations = [noAction, stripHtml, stripIndex]

                const updateChannel = new window.BroadcastChannel(
                    'main-app-update-channel'
                )
                updateChannel.addEventListener('message', event => {
                    let updatedUrl = event.data.payload.updatedUrl
                    mutations.forEach(mutationfunc => {
                        updatedUrl = mutationfunc(updatedUrl)
                        if (updatedUrl === location.href) {
                            console.log(
                                'This page has been updated. Please refresh the page.'
                            )
                        }
                    })
                })

                const updatesChannel = new BroadcastChannel('scan')
                updatesChannel.addEventListener('message', async event => {
                    const { cacheName, updatedUrl } = event.data.payload

                    // Do something with cacheName and updatedUrl.
                    // For example, get the cached content and update
                    // the content on the page.
                    const cache = await caches.open(cacheName)
                    const updatedResponse = await cache.match(updatedUrl)
                    const updatedText = await updatedResponse.text()
                    console.log(`${cache} ${updatedResponse} ${updatedText}`)
                })
            }

            function listenForWaitingServiceWorker(reg, callback) {
                function awaitStateChange() {
                    reg.installing.addEventListener('statechange', function() {
                        if (this.state === 'installed') callback(reg)
                    })
                }
                if (!reg) return
                if (reg.waiting) return callback(reg)
                if (reg.installing) awaitStateChange()
                reg.addEventListener('updatefound', awaitStateChange)
            }

            // reload once when the new Service Worker starts activating
            var refreshing
            navigator.serviceWorker.addEventListener('controllerchange', function() {
                if (refreshing) return
                refreshing = true
                debugger
                window.location.reload()
            })

            function promptUserToRefresh(reg) {
                // this is just an example
                // don't use window.confirm in real life; it's terrible
                // if (window.confirm('New version available! OK to refresh?')) {
                //     reg.waiting.postMessage('skipWaiting')
                // }

                vm.$snackbar.open({
                    message: 'New version available! Click OK to update',
                    position: 'is-bottom',
                    indefinite: true,
                    queue: false,
                    onAction: () => {
                        reg.waiting.postMessage('skipWaiting')
                    }
                })
            }

            function forceReloadOnUpdate(reg) {
                reg.waiting.postMessage('skipWaiting');
            }

            navigator.serviceWorker
                .register('/service-worker.js')
                .then(function(registration) {
                    // Track updates to the Service Worker.
                    if (!navigator.serviceWorker.controller) {
                        // The window client isn't currently controlled so it's a new service
                        // worker that will activate immediately
                        return
                    }

                    listenForWaitingServiceWorker(registration, forceReloadOnUpdate)

                    // Check for new updates every hour
                    if (registration.update){
                        window.setInterval(registration.update, 60 * 60 * 1000);
                    }
                })
        }
    },

    router,
    store,
    render: h => h(App)
}).$mount('#app')