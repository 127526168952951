<template>
  <div class="container has-text-centered column is-5 statusbar">
    <transition name="slideup">
      <div class="cloud-status offline" v-show="!isOnline">
        <b-icon icon="cloud-off-outline"></b-icon>
        <span>Your Device is Offline</span>
          <div class="scans-waiting">
            <span>Scans waiting to sync: </span> {{ getSavedCount }}
          </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '../event-bus.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "StatusBar",
  props: {
    msg: String
  },
  data () {
    return {
      isOnline: true
    }
  },
  computed: {
    ...mapGetters(['getSavedCount']),
  },
  methods: {
    ...mapActions(['updateSavedCount']),
  },
  mounted () {
    EventBus.$on('network.changed', data => {
      //console.log(`network is ${data ? 'online' : 'offline'}`)
      this.isOnline = data
    })

    this.updateSavedCount();
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
