import LoadingIndicator from '@/components/LoadingIndicator'
import { EventBus } from '../event-bus.js'

var MobileDetect = require('mobile-detect')

export default {
    components: { LoadingIndicator },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        async onInit(promise) {
            this.loading = true
            const loadingComponent = this.$loading.open()

            try {
                const md = new MobileDetect(window.navigator.userAgent)
                if (process.env.NODE_ENV !== 'production') {
                    console.log(md)
                }

                if (md.is('iOS') && md.version('iOS') < 11.3) {
                    EventBus.$emit(
                        'warning',
                        'Your version of iOS may not be fully supported. Please update your device.'
                    )
                }

                await promise

                EventBus.$emit('success')
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    EventBus.$emit(
                        'error',
                        'To detect and decode QR codes this page needs access to your camera'
                    )
                } else if (error.name === 'NotFoundError') {
                    EventBus.$emit(
                        'error',
                        'Seems like you have no suitable camera on your device.'
                    )
                } else if (error.name === 'NotSupportedError') {
                    EventBus.$emit(
                        'error',
                        "Seems like this page is served in non-secure context. Your browser doesn't support that"
                    )
                } else if (error.name === 'NotReadableError') {
                    EventBus.$emit(
                        'error',
                        "Couldn't access your camera. Is it already in use?"
                    )
                } else if (error.name === 'OverconstrainedError') {
                    EventBus.$emit(
                        'error',
                        "Constraints don't match any installed camera. Did you asked for the front camera although there is none?"
                    )
                } else {
                    EventBus.$emit('error', 'UNKNOWN ERROR: ' + error.message)

                    console.error(error)
                }
            } finally {
                this.loading = false
                loadingComponent.close()
            }
        }
    }
}