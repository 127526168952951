import Dexie from 'dexie'

const db = new Dexie('scan-app')

db
    .version(4)
    .stores({
        scans: '++id, token',
        config: 'id, token, event'
    })
    .upgrade(function() {})

db
    .version(3)
    .stores({
        scans: '++id, token',
        tokens: 'id, token'
    })
    .upgrade(function() {})

db
    .version(2)
    .stores({
        scans: '++id, token'
    })
    .upgrade(function() {})
db.version(1).stores({
    scans: 'id'
})

export default db