import Axios from './http'

export default {
    async login(creds) {
        try {
            const res = await Axios.post('login', creds)
            return res.data
        } catch (e) {
            console.error(e) // 💩
            return e
        }
    },

    async scan(token, data, is_kiosk = false) {
        try {
            const res = await Axios.post('scan', { login_token: token, scan: data, kiosk: is_kiosk });
            return res.data;
        } catch (e) {
            console.error(e); // 💩
        }
    },

    /*
              // POST to /api/v1/exhibitor_scan
              {
              "email": "demo@text.com",
              "complete": false,
              "scan" : ["87E1EE55-B5BD-4AF7-834A-1F3DFFFF7346", "87E1EE55-B5BD-4AF7-834A-1F3DFFFF7346"]
              }
              
              returns same result as main scan
              setting complete to true will trigger an email of all attached scans to that email address
              
              */
    async exhibitor_scan(email, event, complete = false, data) {
        try {
            const res = await Axios.post('exhibitor_scan', {
                email,
                event_id: event,
                complete,
                scan: data
            })
            return res.data
        } catch (e) {
            console.error(e) // 💩
        }
    },

    async exhibitor_login(email, event) {
        try {
            const res = await Axios.post('exhibitor_login', {
                email,
                event
            })
            return res.data
        } catch (e) {
            console.error(e) // 💩
        }
    },

    async exhibit_detail(event) {
        try {
            const res = await Axios.post('exhibit_detail', {
                event
            })
            return res.data
        } catch (e) {
            console.error(e) // 💩
        }
    }
}