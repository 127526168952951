import Vue from 'vue'
import Vuex from 'vuex'
import api from './api/api'
import db from './database'
import { EventBus } from './event-bus.js'

Vue.use(Vuex)

const idleTimeoutValue = 60 * 1000 // seconds
const v = '0.2.8'

const state = {
    token: '', // user login token or email (for exhibitors)
    latest_scan: [],
    savedCount: 0,
    event: '',
    eventData: {},
    cameraIdleTimeout: idleTimeoutValue,
    laserScanEnabled: true,
    version: v
}

const getters = {
    getToken: state => {
        return state.token
    },
    getEvent: state => {
        return state.event
    },
    getProcessedTokens: state => {
        return state.latest_scan.map(scan => scan.ticket_token)
    },
    getSavedCount: state => {
        return state.savedCount
    },

    version: state => `v ${state.version}`,

    cameraIdleTimeout: state => state.cameraIdleTimeout,
    laserScanEnabled: state => state.laserScanEnabled,
    isExhibitor: state => state.token && state.event,

    currentEvent: state => state.eventData
}

const mutations = {
    SAVE_TOKEN(state, { login_token }) {
        state.token = login_token
    },

    SAVE_SCAN_RESULT(state, data) {
        state.latest_scan = data
    },

    UPDATE_SAVED_COUNT(state, { count }) {
        state.savedCount = count
    },

    UPDATE_CONFIG(state, { token, event, eventData }) {
        state.token = token
        state.event = event
        state.eventData = eventData
    },

    FINISH_SESSION(state) {
        state.token = ''
    },
    UPDATE_IDLE_TIMEOUT(state, { enabled }) {
        state.cameraIdleTimeout = enabled ? idleTimeoutValue : 0 // 0 -- no timeout
    },
    TOGGLE_LASER_SCAN(state, { enabled }) {
        state.laserScanEnabled = enabled
    }
}

const actions = {
    async toggleLaserScanOption({ commit, getters, dispatch }, { enabled }) {
        commit('TOGGLE_LASER_SCAN', { enabled })
    },
    async login({ commit, dispatch }, { username, password, router }) {
        const res = await api.login({ username, password })

        if (res && res.result) {
            db.config
                .put({ id: 1, token: res.data.login_token })
                .then(lastKey => {
                    console.log(`Saved to db, with lastKey: ${lastKey}`)
                        // notify?
                })
                .catch(err => {
                    console.error('Error: ' + (err.stack || err))
                })
            commit('SAVE_TOKEN', res.data)
            router.push('/scan')
        } else {
            console.log(`Error logging in: ${res}`)
            EventBus.$emit('error', `Unable to login: ${res.error ? res.error : res}`)
        }
    },

    async logout({ commit, dispatch }, { router }) {
        db.config
            .clear()
            .then(() => {
                commit('FINISH_SESSION')
                router.push('/login')
            })
            .catch(err => {
                console.error('Error: ' + (err.stack || err))
                EventBus.$emit('error', `Unable to clear tokens: ${err.stack || err}`)
            })
    },

    async saveScan({ commit, getters, dispatch }, { data, name, saveToDb }) {

        var result = {
            valid: true
        };

        function save(data) {
            db.scans
                .bulkPut(data)
                .then(lastKey => {
                    console.log(`Saved to db, with lastKey: ${lastKey}`)
                        // notify?
                })
                .catch(err => {
                    console.error('Error: ' + (err.stack || err))
                })
        }

        if (navigator.onLine && !!getters.getToken) {
            //push to api
            const is_kiosk = data.some(t => 'kiosk' in t && t.kiosk === true);
            const res = await api.scan(getters.getToken, data.map(t => t.token), is_kiosk);

            if (res && res.result) {
                commit('SAVE_SCAN_RESULT', res.data)
            } else if (res && res.error) {
                const msg = `Error saving scan: ${res.error}`
                console.log(`${msg}: ${JSON.stringify(res)}`)
                EventBus.$emit('error', msg)
                await dispatch('updateSavedCount')
                return;
            } else {
                const msg = `Unknown error saving scan`
                console.log(`${msg}: ${JSON.stringify(res)}`)
                EventBus.$emit('error', msg)
                await dispatch('updateSavedCount')
                return;
            }

            // Check per scan result
            if (res.data && res.data.length === 1) {
                result = res.data[0];
            }

            // for debugging
            if (saveToDb) {
                save(data)
            }
        } else {
            //save to indexedDB
            save(data)
        }

        result.name = name;

        await dispatch('updateSavedCount')

        EventBus.$emit('scan.complete', result)
    },

    async processSavedScans({ commit, dispatch, getters }) {
        var tokens = []
        await db.scans.each(scan => {
            if (scan.token) tokens.push(scan)
        })
        console.log(`App is online, found ${tokens.length} unprocessed tokens`)
        if (tokens.length > 0) {
            if (getters.isExhibitor) {
                console.log(`processing ticket scans for Exhibitor..`)
                await dispatch('completeScans', { data: tokens })
            } else {
                console.log(`processing ticket scans..`)
                await dispatch('saveScan', { data: tokens, name: tokens.length + " scans" })
            }

            await dispatch('deleteProcessed')
        }
    },

    async deleteProcessed({ commit, dispatch, getters }) {
        //delete processed entries
        try {
            var res = await db.scans
                .where('token')
                .anyOf(getters.getProcessedTokens)
                .delete()
            console.log(`Deleted ${res} processed scans`)

            await dispatch('updateSavedCount')
        } catch (error) {
            console.log(`Error deleting processed scans: ${error}`)
        }
    },

    async updateSavedCount({ commit }) {
        var res = await db.scans.count()

        commit('UPDATE_SAVED_COUNT', { count: res })
    },

    async loadSavedToken({ commit }) {
        var res = await db.config.where({ id: 1 }).first()

        if (res) {
            commit('UPDATE_CONFIG', {
                token: res.token,
                event: res.event,
                eventData: res.eventData
            })
        } else {
            console.log(`No saved token\email or other config`)
        }
        if (navigator.onLine) {}
    },

    async completeScans({ commit, dispatch, getters }, { data }) {
        // load scans
        var scans = data || (await db.scans.toArray()) || []

        if (scans.length > 0 && getters.getToken && getters.getEvent) {
            console.log(`Found ${scans.length} and IS logged in, api push..`)
                //push to api
            const res = await api.exhibitor_scan(
                getters.getToken,
                getters.getEvent,
                true,
                scans.map(t => t.token).filter(t => t != null)
            )

            if (res && res.result) {
                const data = Object.assign({}, res.data)
                console.log(data)
                commit('SAVE_SCAN_RESULT', data.scan)
                EventBus.$emit(
                    'success',
                    `Successfully processed ${res.data.scan.length} scans`
                )
                await dispatch('deleteProcessed')
            } else if (res && res.error) {
                const msg = `Error saving scan: ${res.error}`
                console.log(`${msg}: ${JSON.stringify(res)}`)
                EventBus.$emit('error', msg)
            } else {
                const msg = `Unknown error saving scan`
                console.log(`${msg}: ${JSON.stringify(res)}`)
                EventBus.$emit('error', msg)
            }
        } else {
            console.log('No scans to process or not logged in')
        }
    },

    async exhibitorLogin({ commit, dispatch }, { email, router, route }) {
        let event = ''
        if (!!route.query.event) {
            event = route.query.event
            console.log(`Processing ${event} and email ${email}`)
        } else {
            console.log(`Event id is missing, stop exhibitor login!`)
            return false
        }

        const res = await api.exhibitor_scan(email, event, false, [])

        if (res && res.result) {
            db.config
                .put({ id: 1, token: email, event: event, eventData: res.data })
                .then(lastKey => {
                    //console.log(`Saved to db, with lastKey: ${lastKey}`)
                    // notify?
                })
                .catch(err => {
                    console.error('Error: ' + (err.stack || err))
                })
            commit('UPDATE_CONFIG', { email, event, eventData: res.data })
            router.push('/scan')
        } else {
            console.log(`Error in exhibitor loggin: ${res}`)
            EventBus.$emit(
                'error',
                `Unable to login: ${res && res.error ? res.error : res}`
            )
        }
    }
}

export default new Vuex.Store({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
})