import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Help from './views/Help.vue'
import Scan from './views/Scan.vue'
import Kiosk from './views/Kiosk.vue'
import Layout from './views/Layout.vue'
import Settings from './views/Settings.vue'
import NotFoundView from '@/components/404.vue'
import LoginView from '@/components/Login.vue'

import store from './store.js'

Vue.use(Router)

function requireAuth(to, from, next) {
    if (store.getters.getToken != '') {
        next()
    } else {
        next('/login')
    }
}

export default new Router({
    //mode: 'history',
    routes: [{
            // not found handler
            path: '*',
            component: NotFoundView
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView
        },
        {
            path: '/kiosk',
            name: 'kiosk',
            component: Kiosk,
            beforeEnter: requireAuth
        },
        {
            path: '/',
            name: 'layout',
            component: Layout,
            beforeEnter: requireAuth,
            children: [{
                    path: '/home',
                    name: 'home',
                    // component: Home
                    component: Scan,
                    beforeEnter: requireAuth
                },
                {
                    path: '/help',
                    name: 'help',
                    component: Help
                },
                {
                    path: '/scan',
                    name: 'scan',
                    component: Scan,
                    beforeEnter: requireAuth
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: Settings
                }
            ]
        }
    ]
})