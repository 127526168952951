<template>
  <div class="hero-foot"
       v-show="!isScanning">
    <div class="container">
      <div class="tabs is-centered">
        <ul class="copyrights">
          <li>Copyright My Industry Events {{year}}. Powered by
            <a href="https://acronym.co.nz">Acronym Ltd.</a>
          </li>
        </ul>

      </div>
      <div class="is-centered is-size-7">{{version}}</div>
      <!-- <div class="is-centered is-size-7">
        <button @click="$store.dispatch('deleteProcessed')">delete</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { EventBus } from '../event-bus.js';
import { mapGetters } from 'vuex';

export default {
  name: "FooterBar",
  props: {
    msg: String
  },
  computed: {
    year () {
      return (new Date()).getFullYear()
    },
    ...mapGetters(['version']),
  },
  data () {
    return {
      isScanning: false
    }
  },

  mounted () {
    EventBus.$on('scan.changed', data => this.isScanning = !!data)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
