<template>
  <div class="hero-body">
    <div class="container has-text-centered">

      <div class="auto scan-interface-main">
        <h1 class="title is-2">
        </h1>
        <h2 class="subtitle is-4">
        </h2>
        <br>
        <div v-if="laserScanEnabled" class="scan-helper-text notification is-primary is-large">
          Please Scan your QR Code using the QR Code Scanner
          <b-input type="text" v-model="scanInput" placeholder="Scan Ticket" ref="scanInput" custom-class="scan-barcode" 
            @blur="resetFocusOnLaserScannerInput()"
            @keyup.native="laserScannerInputChanged">
          </b-input>
        </div>
        <img v-if="laserScanEnabled" src="../assets/images/mobile_qr_code.jpg" style="height: 200px;margin-left: auto; margin-right: auto;">
        <a v-else @click="click()"
           class="button is-primary camera-button is-large is-info is-outlined">
          <b-icon icon="camera"
                  class="material-icons camera-button"></b-icon>
          <span>{{label}}</span>

        </a>
      </div>
      <div class="scan-result-last container has-text-centered">
        <qrcode-reader v-if="startScanning"
                       @decode="onDecode"
                       @init="onInit"
                       :paused="paused">
          <div class="scantarget"></div>
          <canvas ref="canvas"
                  class="points"></canvas>
          <LoadingIndicator v-show="loading" />
        </qrcode-reader>

      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

// use customised one with worker processing
import QrcodeReader from '@/components/QrcodeReader.vue'
import debounce from 'lodash/debounce'
import InitHandler from '@/mixins/InitHandler'
import { EventBus } from '../event-bus.js';
import bleepsound from '../assets/bleep.mp3'
import bleepsoundFail from '../assets/fail.mp3'

var audiobeep = new Audio(bleepsound);
var audiobeepFail = new Audio(bleepsoundFail);

var previousToast = null


export default {
  name: 'scan',

  props: ['id', 'product', 'url'],

  data: function () {
    return {
      startScanning: false,
      qr: {},
      points: [],
      paused: false,
      to: 0,
      snack: null,
      scanInput: "",
      constraints: {

        video: {
          facingMode: { ideal: 'environment' }, // use rear camera if available
          width: { min: 360, ideal: 720, max: 720 }, // constrain video width resolution
          height: { min: 240, ideal: 480, max: 480 } // constrain video height resolution
        },
        audio: false, // don't request microphone access
      },

    }
  },

  mixins: [InitHandler],

  components: { QrcodeReader },

  computed: {
    ...mapGetters(['currentEvent', 'laserScanEnabled']),
    label () {
      return this.startScanning ? 'Stop Scanning' : 'Scan Tickets'    },
    year () {
      return (new Date()).getFullYear()
    },
    exhibitorname () {
      return this.currentEvent.booth
    },
    ...mapGetters(['cameraIdleTimeout'])
  },

  watch: {
    startScanning (oldVal, newVal) {
      EventBus.$emit('scan.changed', this.startScanning)
    }
  },

  mounted () {

    if (this.laserScanEnabled) {
      this.resetFocusOnLaserScannerInput();
    }
    EventBus.$on('network.changed', data => {
      if (data) {
        //network changed to online
        this.processSavedScans();
      }
    })

EventBus.$on('scan.complete', result => {

      if(!result || result.valid){
        
        if(previousToast) previousToast.close()

        previousToast = this.$toast.open({
          message: `Scan Success! Ticket for ${result.name} recorded.`,
          type: 'is-success custom-class',
          position: 'is-top',
          duration: 5000,
          queue: false
        });
      }

      if(result && !result.valid){
        this.$snackbar.open({
          message: `Scan Failed! ${result.error}.`,
          type: 'is-danger custom-class',
          position: 'is-top',
          duration: 5000,
          queue: false,
          actionText: 'OK',
          indefinite: true,
          onAction: () => { }          
        });
      }

      if (this.snack) {
        this.snack.close();
      }
      this.startScanning = false;
  })

  },

  methods: {
    ...mapActions(['saveScan', 'processSavedScans', 'completeScans']),
    resetFocusOnLaserScannerInput() {
      this.$refs["scanInput"].focus();
    },
    async laserScannerInputChanged($event) {
      var re = /}\s*$/ig;
      if (re.test(this.scanInput)) {
        await this.onDecode(this.scanInput);
        this.scanInput = "";
      } else {
        console.warn(this.scanInput);
      }
    },
    click () {
      // preload sound on click event
      audiobeep.play();
      audiobeep.pause();

      audiobeepFail.play();
      audiobeepFail.pause();

      this.startScanning = !this.startScanning

      this.snack = this.$snackbar.open({
        message: 'Scanning for QR codes',
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'STOP CAMERA',
        indefinite: true,
        onAction: () => {
          this.startScanning = !this.startScanning
        }
      })
      this.handleTimeout()
    },

    handleTimeout () {
      const vm = this

      if (this.to) {
        console.log(`clearing runnning timeout ${this.to}`)
        clearTimeout(this.to)
      }
      //handle camera timeout
      if (!!this.cameraIdleTimeout) {
        const timeout = this.cameraIdleTimeout

        vm.to = setTimeout(function () {
          vm.startScanning = false
          if (vm.snack)
            vm.snack.close()
        }, timeout)

        console.log(`started timeout ${this.to}`)
      }
    },

    async onDecode (content) {
      console.log(content);

      this.handleTimeout()

      try {

        try {
          this.qr = JSON.parse(content);
        } catch (err) {
          audiobeepFail.play();
          EventBus.$emit('error', 'Invalid QR Code for event');
          return
        }

        // play sound first
        audiobeep.play();

        await this.saveScan({ data: [{ token: this.qr.token }], name: this.qr.name, saveToDb: false });

        this.qr = null;

      } catch (error) {

        console.error(error)

        this.$snackbar.open({
          duration: 5000,
          message: `Error when scanning or saving: ${error}`,
          position: 'is-top',
          type: 'is-danger',
          queue: false,
          actionText: 'OK',
          indefinite: true,
          onAction: () => { }      
        })
      }

    },

    onLocate (points) {
      // ...
      if (points.length > 0) {
        this.points = points
      } else {
        this.clearPoints()
      }
    },

    // avoid flickering when value changes quickly
    clearPoints: debounce(function () {
      this.points = []
    }, 100),



  }
}
</script>

<style scoped>
.points {
  position: absolute;
  width: 100%;
  height: 100%;
}




</style>