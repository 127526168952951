<template>
  <div id="app-layout">
    <navigation></navigation>
    <status-bar></status-bar>
    <router-view></router-view>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import StatusBar from '@/components/StatusBar.vue';
import FooterBar from '@/components/Footer.vue';

export default {
  name: "layout",
  components: {
    Navigation,
    StatusBar,
    FooterBar,
  }
}
</script>